
@import url('https://fonts.googleapis.com/css2?family=Italiana&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/* GENERAL ELEMENTS */

h1 {
    font-size: 55px;
}

p {
    color: black;
}

a {
    text-decoration: none;
    color: inherit;
}

ul {
    list-style-type: none;
    padding: 0;
}


/* --MAIN-- */
/* Desktop */
@media only screen and (min-width: 768px) {
    main {
        margin: 0px;
    }

    .landing-container .header-text {
        margin: 0px 0px 25px 0px;
        font-size: 80px;
    }

    .landing-container .sub-header {
        font-family: 'Italiana';
        font-style: italic;
        font-size: 24px;
        letter-spacing: 0.1rem;
    }
    
    .intro { 
        margin: 185px 0px 0px 0px;
    } 

    .intro-text-container {
        margin: 0px 0px 35px 0px;
    }
 
}

/* Tablet */
@media only screen and (max-width: 767px) {
    main {
        margin: 100px 0px 0px 0px;
    }

    .header-text {
        font-size: 55px;
        margin: 0px 0px 15px 0px;
    }

    .landing-container .sub-header {
        font-family: 'Italiana';
        font-style: italic;
        font-size: 12px;
        letter-spacing: 0.1rem;
    }

    .intro {
        width: 100%;
    }

    .intro-text-container {
        margin: 0px 0px 35px 0px;
    }
}

/* Mobile */
@media only screen and (max-width: 320px) {
    .header-text {
        margin: 0px;
        font-size: 40px;
    }

    .landing-container .sub-header {
        font-family: 'Italiana';
        font-style: italic;
        font-size: 12px;
        letter-spacing: 0.1rem;
        
    }

    
    .intro-text-container {
        margin: 0px 0px 35px 0px;
    }
}


/* Rest */



main {
    text-align: center;
    width: 100%;
}

.header-text {
    font-family: "Italiana", sans-serif;
    font-weight: 400;
    font-style: normal;   
    padding: 0px 20px 0px 20px;
}

.intro-img {
    width: 100%;
    height: auto;
}

/* --SECTIONS-- */
/* Desktop */
@media only screen and (min-width: 768px) {
    .section-images-container {
        display: flex;
    }

    .section-image-container {
        width: 400px;
        height: 600px;
    }

    .section-header {
        padding: 0px 25px 25px 25px;
    }

    .section-text-left {
        font-size: 35px;
        letter-spacing: 0.1rem;
    }

    .section-text-right {
        font-size: 22px;
        padding: 0;
        padding: 9px 0px 0px 0px;
    }

    .section-text-right:hover {
        text-decoration: underline darkgrey;
    }

    .services-header {
        padding: 0px 50px 25px 50px;
    }
}

/* Tablet */
@media only screen and (max-width: 767px) {
    .section-images-container {
        text-align:center;
    }

    .section-image-container {
        margin: 0 auto;
        width: 300px;
        height: 300px;
        padding: 25px 0px 25px 0px;
    }

    .section-header {
        padding: 0px 15px 0px 15px;
    }

    .section-text-right {
        padding: 5px 0px 0px 0px;
    }

    .services-header {
        padding: 0px 25px 25px 25px;
    }

    .section-text-left {
        font-size: 35px;
        letter-spacing: 0.1rem;
    }
    
    .section-text-right {
        font-family: "Lato";
        font-weight: 400;
        text-decoration: underline;
        letter-spacing: 0.1rem;
    }
}

/* Tablet/Mobile */
@media only screen and (max-width: 766px) {
    .section-image-container {
        width: 450px;
        height: 450px;
    }

    .section-text-left {
        font-size: 28px;
        letter-spacing: 0.1rem;
    }
    
    .section-text-right {
        font-size: 14px;
        font-family: "Lato";
        font-weight: 400;
        text-decoration: underline;
        letter-spacing: 0.1rem;
        padding: 8px 0px 0px 0px;
    }
}

@media only screen and (max-width: 550px) {
    .section-image-container {
        width: 350px;
        height: 350px;
    }
}

@media only screen and (max-width: 450px) {
    .section-image-container {
        width: 250px;
        height: 250px;
    }
}

@media only screen and (max-width: 320px) {
    .section-text-left {
        font-size: 25px;
        letter-spacing: 0.1rem;
    }
    
    .section-text-right {
        font-size: 12px;
        font-family: "Lato";
        font-weight: 400;
        text-decoration: underline;
        letter-spacing: 0.1rem;
        padding: 9px 0px 0px 0px;
    }

    .section-image-container {
        margin: 0 auto;
        width: 220px;
        height: 220px;
        padding: 25px 0px 25px 0px;
    }

    .section-image-text {
        font-size: 12px;
        font-family: "Lato";
        margin: 5px 0px 0px 0px;
        letter-spacing: 0.2rem;
    }
}

/* Rest */

.portfolio-section {
    margin-top: -7px;
    font-family: "Italiana", sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color: rgb(241, 243, 243);
    padding: 50px 25px 75px 25px;
}

.blog-section {
    font-family: "Italiana", sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color: white;
    padding: 50px 25px 75px 25px;    
}

.services-section {
    padding: 50px 0px 0px 0px;
}

.services-header {
    font-family: "Italiana", sans-serif;
    font-weight: 400;
    font-style: normal;
    display: flex;
    justify-content: space-between;
}

.section-header {
    font-family: "Italiana", sans-serif;
    font-weight: 400;
    font-style: normal;
    display: flex;
    justify-content: space-between;
}

.section-images-container {
    align-items: center;
    justify-content: space-between;
    margin: 0px 25px 0px 25px;
}

.section-image-container {
    text-align: left;
}

.section-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.section-image-text {
    font-family: "Lato";
    margin: 5px 0px 0px 0px;
    letter-spacing: 0.2rem;
}

.services-images {
    display: flex;
    justify-content: space-between;
}

.service-image-container {
    width:50%;
    height: auto;
    text-align: left;
}

.service-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}