@media only screen and (min-width: 768px) {
    .individual-blog-container {
        text-align: center;
        margin: 180px 0px 80px 0px;
        font-family: 'Italiana';
        
    }

    .placeholder-text {
        font-size: 85px;
        font-weight: 300;
    }
}

@media only screen and (max-width: 767px) {
    .individual-blog-container {
        text-align: center;
        margin: 100px 0px 80px 0px;
        font-family: 'Italiana';
    }

    .placeholder-text {
        padding: 25px 0px 0px 0px;
        font-size: 45px;
        font-weight: 300;
    }

}