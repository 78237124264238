/* Desktop */

@media only screen and (min-width: 768px) {
    .header-mobile {
        display: none;
    }

    .mobile-nav {
        display: none;
    }

    header {
        display: flex;
        background-color: white;
        position: fixed;
        top: 0;
        height: 150px;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 0px 25px 0 25px;
    }

    header:after {
        content: "";
        display: block;
        height: 10px;
        width: 100%;
        background: linear-gradient(to bottom, rgb(208, 208, 208), rgb(255, 255, 255));
        position: absolute;
        bottom: 0;
        left: 0;
        transition: opacity 0.3s;
      }

    .scrolled header:after {
        opacity: 0;
    }

    .icon {
        height: 100px;
    }

    .nav-container {
        display: flex;
        justify-content: space-between;
        padding: 0px 100px 0px 0px;
    }
    
    .nav-item {
        display: flex;
        align-items: center;
        margin: 0 15px 0 15px;
        height: auto;
        width: auto;
    }

    .nav-item-text:hover {
        border-bottom: solid rgb(109, 101, 89);
    }
    
    .nav-button {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 0px 0px 15px;
        height: 50px;
        width: 150px;
        background-color: rgb(109, 101, 89);
    }
}

/* Tablet */

@media only screen and (max-width: 767px) {
    .header-desktop {
        display: none;
    }

    .icon {
        height: 75px;
    }

    header {
        display: flex;
        background-color: white;
        position: fixed;
        top: 0;
        height: 100px;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 0px 15px 0 15px;
    }

    .menu-icon {
        height: 40px;
        width: 40px;
        margin: 0px 50px 0px 0px;
    }

    .cross {
        height: 50px;
        width: 50px;
        margin: 0px 50px 0px 0px;
    }

    .mobile-nav-container {
        display: none;
    }

    .mobile-nav-container.show {
        display: block;
        text-align: center;
    }

    .mobile-nav {
        text-align: center;
        position: fixed;
        color: black;
        background-color: white;
        font-size: 30px;
        font-family: "Lato";
        width: 100%; 
        height: 365px;
    }

    .mobile-nav-list {
        margin: 0px 0px 15px 0px;
    }

    .mobile-nav-item {
        padding: 0px 0px 15px 0px;
    }

    .mobile-nav-button-container {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .mobile-nav-button {
        color: white;
        margin: 0px;
        padding: 15px 0px 0px 0px;
        height: 60px;
        width: 300px;
        background-color: rgb(109, 101, 89);
    }
}

/* Mobile */

@media only screen and (max-width: 450px) {
    .menu-icon {
        height: 35px;
        width: 35px;
        margin: 0px 50px 0px 0px;
    }
}