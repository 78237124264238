/* Desktop */

@media only screen and (min-width: 768px) {
    .blogs-container {
        margin: 150px 0px 25px 0px;
        padding: 0px 25px 0px 25px;
    }

    .blogs-container .header-container {
        padding: 50px 0px 0px 0px;
        margin: 125px 0px 25px 0px;
    }

    .blogs-container .header-text {
        margin: 0px 0px 20px 0px;
    }

    .blogs-container .blog-grid-row {
        display: flex;
    }

    .blogs-container .blog-container {
        width: 50%;
        height: 1000px;
        padding: 15px;
        margin: 0px;
    }

    .blogs-container {
        padding: 0px 25px 0px 25px;
    }
    
    .blogs-container .blog-img {
        width: 100%;
        height: 80%;
        object-fit: cover;
    }
    
    .blogs-container .blog-heading {
        font-size: 30px;
        font-family: 'Italiana';
        margin: 15px 0px 0px 0px;
    }
    
    .blogs-container .read-more-text {
        font-style: italic;
    }
}

/* Mobile */

@media only screen and (max-width: 767px) {
    .blogs-container {
        padding: 0px 25px 0px 25px;
    }
    
    .blogs-container .header-container {
        margin: 100px 0px 0px 0px;
    }

    .blogs-container .header-text {
        margin: 0px 0px 15px 0px;
    }
    
    .blogs-container .blog-container {
        padding: 15px;
        margin: 0px 0px 15px 0px;
    }
    
    .blogs-container .blog-img {
        width: 100%;
        height: 80%;
        object-fit: cover;
    }
    
    .blogs-container .blog-heading {
        font-size: 30px;
        font-family: 'Italiana';
        margin: 15px 0px 0px 0px;
    }

    .blogs-container .blog-description {
        margin: 15px 0px 10px 0px;
    }
    
    .blogs-container .read-more-text {
        font-size: 13px;
        font-style: italic;
    }
}