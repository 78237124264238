/* DESKTOP */

@media only screen and (min-width: 768px) {
    .portfolio-container {
        margin: 150px 0px 0px 0px;
    }

    .portfolio-container .header-container {
        text-align: left;
        padding: 15px 0px 0px 0px;
        margin: 0px 0px 35px 25px;
    }

    .portfolio-container .header-text {
        margin-bottom: 25px;
    }

    .portfolio-container .sub-header-text {
        font-size: 18px;
        padding: 0px 0px 0px 25px;
        font-family: 'Italiana';
        font-size: 25px;
    }
    
    .portfolio-container .grid-row {
        display: flex;
        justify-content: space-around;
        margin: 0px 0px 25px 0px;
    }
    
    .portfolio-container .grid-item {
        width: 600px;
        height: auto;    
    }
    
    .portfolio-container .grid-item-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }    
}

/* MOBILE */

@media only screen and (max-width: 767px) {
    .portfolio-container {
        margin: 0px 0px 0px 0px;
        width: 100%;
        text-align: center;
    }

    .portfolio-container .header-container {
        margin: 0px 0px 15px 0px;
        text-align: left;
        padding: 0px 0px 0px 10px;
    }

    .portfolio-container .header-text {
        margin: 0px 0px 20px 0px;
    }

    .portfolio-container .sub-header-text {
        display: block;
        font-family: 'Italiana';
        font-size: 18px;
        padding: 0px 0px 0px 25px;
    }

    .portfolio-container .grid-container {
        width: 85%;
        margin-left: 4%;
    }

    .portfolio-container .grid-row {
        margin: 0px 0px 25px 0px;
        text-align: center;
    }
    
    .portfolio-container .grid-item {
        padding: 15px;
        width: 100%;
        height: auto;    
    }
    
    .portfolio-container .grid-item-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }   
}