/* Desktop */
@media only screen and (min-width: 768px) {
    .footer-top {
        height: 600px;
    }

    .footer-top-text-container {
        padding: 25px 75px 25px 75px;
    }

    .footer-top-text {
        font-size: 80px;
    }

    .subscribe-input {
        font-family:Arial, Helvetica, sans-serif;
        height: 50px;
        width: 250px;
        padding: 0px 0px 0px 15px;
    }

    .subscribe-button {
        height: 50px;
        width: 150px;
    }

    .socials-container {
        
    }
    
    .socials-logo {
        height: 25px;
        width: auto;
        padding: 50px 15px 0px 15px;
    }
}


/* Tablet */
@media only screen and (max-width: 767px) {
    .footer-top {
        height: 600px;
    }

    .footer-top-text-container {
        padding: 25px 50px 25px 50px;
    }

    .footer-top-text {
        font-size: 65px;
    }
    .subscribe-input {
        height: 40px;
        width: 200px;
        padding: 0;
    }

    .subscribe-button {
        height: 40px;
        width: 100px;
    }
}

/* Tablet/Mobile */
@media only screen and (max-width: 600px) {
    .footer-top {
        height: 500px;
    }

    .footer-top-text-container {
        padding: 25px 50px 25px 50px;
    }

    .footer-top-text {
        font-size: 50px;
    }

    .subscribe-input {
        height: 40px;
        width: 200px;
        padding: 0;
    }

    .subscribe-button {
        height: 40px;
        width: 100px;
    }
}

/* Mobile */
@media only screen and (max-width: 450px) {
    .footer-top {
        height: 400px;
    }

    .footer-top-text-container {
        padding: 25px 25px 25px 25px;
    }
    
    .footer-top-text {
        font-size: 40px;
    }

    .footer-nav-item {
        font-size: 12px;
    }

    .subscribe-input {
        font-size: 10px;
        height: 40px;
        width: 200px;
        padding: 0;
    }

    .subscribe-button {
        font-size: 10px;
        height: 40px;
        width: 100px;
    }

    .socials-logo{
        margin-top: 120px;
        padding: 0px 0px 0px 15px;
        height: 15px;
        width: auto;
    }

}

/* Rest */

.footer-top {
    display: flex;
    background-image: url('../../public/img/home/10.png');
    align-items: center;
    justify-content: center;
    width: auto;
    background-size:cover;
    text-align: center;
}

.footer-top-text {
    font-family: 'Italiana';
    color: white;
}

footer {
    display: flex;
    color: white;
    background-color: rgb(109, 101, 89);
    width: 100%;
    height: 300px;
}

.subscribe-container {
    text-align: start;
    width: 50%;
}

.subscribe-header {
    margin: 25px 0px 0px 25px;
    padding: 0px 0px 10px 0px;
}

.subscribe-text {
    font-size: 13px;
    margin: 0px 0px 25px 25px;
}

.subscribe-form {
    margin: 0px 0px 25px 25px;
}

.subscribe-input {
    outline: none;
    font-family: 'Poppins';
    font-weight: 600;
    letter-spacing: 0.1rem;
}

.subscribe-input::placeholder {
    padding-left: 20px;
}

.subscribe-button {
    margin: 25px 0px 0px 0px;
    background-color: white;
    color: gray;
    border: none;
    font-family: 'Poppins';
    font-weight: 600;
    letter-spacing: 0.1rem;
}

.subscribe-message {
    margin: 0px 0px 0px 0px;
    padding-top: 5px;
    font-size: 10px;
}

.subscribe-button:hover {
    cursor: pointer;
}

.footer-nav {
    text-align: end;
    width: 50%;
    padding: 25px 25px 0px 0px;
}

.footer-nav-item {
    padding: 0px 0px 10px 0px;
}

.footer-nav-item .nav-item-text {
    border-bottom: solid white;
    border-width: 1px;
}

