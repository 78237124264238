/* DESKTOP */

@media only screen and (min-width: 768px) {
    .services-container {
        margin: 140px 0px 0px 0px;
    }

    .services-container .header-container {
        text-align: left;
        padding: 50px 0px 0px 0px;
        margin: 0px 0px 40px 25px;
    }

    .services-container .header-text {
        margin: 0px 0px 35px 0px;
    }

    .services-container .sub-header-text {
        font-family: 'Italiana';
        font-size: 25px;
        margin-left: 20px;
        display: block;
    }

    .services-container .grid-row {
        display: flex;
        justify-content: space-around;
        height: 1000px;
        margin: 0px 0px 25px 0px;
    }
    
    .services-container .grid-item  {
        width: 30%;
        height: 880px;
    }

    .services-container .grid-item-portraits  {
        width: 30%;
        height: 880px;
    }

    .services-container .grid-item-travel  {
        width: 30%;
        height: 880px;
    }
    
    .services-container .grid-item-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    
    .services-container .grid-item-text {
        margin: 15px 0px 0px 0px;
        text-align: left;
    }
    
    .services-container .grid-item-text-header {
        font-family: 'Italiana';
        font-size: 35px;
    }
    
    .services-container .contact-section {
        display: flex;
        margin: 75px 25px 0px 25px;
    }
    
    .services-container .contact-section-left {
        text-align: left;
        height: 450px;
        width: 50%;
        padding: 100px 25px 0px 25px;
    }
    
    .services-container .contact-section-left-text {
        font-family: 'Italiana';
        line-height: 75%;
        font-size: 85px;
        letter-spacing: 0.1rem;
    }
    
    .services-container .contact-section-right {
        height: 450px;
        width: 50%;
        text-align: left;
    }
    
}

/* MOBILE */

@media only screen and (max-width: 767px) {
    .services-container {
        margin: 0px 15px 0px 15px;
    }

    .services-container .header-container {
        text-align: left;
        margin: 25px 0px 25px 0px;
    }

    .services-container .header-text {
        margin-bottom: 20px;
    }

    .services-container .sub-header-text {
        display: block;
        font-family: 'Italiana';
        padding: 0px 15px 0px 15px;
        margin: 0px 0px 15px 0px;
        font-size: 18px;
    }

    .services-container .grid-row {
        width: 100%;
        text-align: center;
    }
    
    .services-container .grid-item  {
        height: 625px;
        margin: 0px;
    }

    .services-container .grid-item-travel  {
        height: 635px;
        margin: 0px;
    }
    
    .services-container .grid-item-image {
        width: 280px;
        height: auto;
    }
    
    .services-container .grid-item-text {
        margin: 10px 0px 0px 0px;
        padding: 0px 15px 0px 15px;
        text-align: left;
    }
    
    .services-container .grid-item-text-header {
        font-family: 'Italiana';
        font-size: 35px;
    }
    
    .services-container .contact-section {
        margin: 15px 15px 15px 15px;
    }
    
    .services-container .contact-section-left {
        text-align: left;
        margin: 0px 0px 25px 0px;;
    }
    
    .services-container .contact-section-left-text {
        font-family: 'Italiana';
        line-height: 75%;
        font-size: 60px;
        letter-spacing: 0.1rem;
    }
    
    .services-container .contact-section-right {
        height: 580px;
        text-align: left;
    }
}