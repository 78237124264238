.about-link {
    text-decoration: underline gray;
}

@media only screen and (min-width: 768px) {
    .about-container {
        margin: 150px 0px 0px 0px;
    }

    .about-container .header-container {
        padding: 50px 0px 0px 0px;
        margin: 0px 0px 50px 60px;
    }

    .about-container .header-text {
        margin: 0px 0px 25px 0px;
    }
    
    .about-container .sub-heading-text {
        font-family: 'Italiana';
        font-size: 30px;
        padding: 0px 25px 0px 15px;
        display: flex;
    }
    
    .about-container .about-img-container {
        width: 100%;
        height: auto;
        text-align: center;
    }
    
    .about-container .about-img {
        height: auto;
        width: 90%;
        object-fit: cover;
    }

    .about-container .about-text {
        padding: 0px 90px;
        margin: 25px 0px 25px 0px;
        letter-spacing: 0.1rem;
    }
    
    .about-container .contact-container {
        display: flex;
        margin: 80px 0px 60px 0px;
        padding: 0px 50px 0px 50px;
    }
    
    .about-container .contact-left {
        width: 50%;
        margin: 0px 0px 15px 0px;
    }

    .about-container .contact-left-text {
        font-family: 'Italiana';
        font-size: 85px;
        margin: 0px 0px 0px 25px;
    }
    
    .about-container .contact-right {
        width: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .about-container {
        margin: 100px 0px 0px 0px;
    }

    .about-container .header-text {
        margin: 0px 0px 15px 0px;
    }
    
    .about-container .sub-heading-text {
        font-family: 'Italiana';
        font-size: 26px;
        padding: 0px 25px 25px 25px;
        display: flex;
    }
    
    .about-container .about-img-container {
        width: 100%;
        height: auto;
        text-align: center;
    }
    
    .about-container .about-img {
        height: auto;
        width: 90%;
        object-fit: cover;
    }

    .about-container .about-text {
        padding: 0px 20px;
        margin: 25px 0px 25px 0px;
        letter-spacing: 0rem;
        font-size: 13px
    }
    
    .about-container .contact-container {
        margin: 10px 0px 20px 0px;
        padding: 0px 15px 0px 15px;
    }

    .about-container .contact-left-text {
        font-size: 55px;
        font-family: 'Italiana';
        margin: 0px;
        padding: 15px 0px 0px 5px;
    }
}